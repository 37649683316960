<template>
  <div id="data-list-list-view" class="panel-control data-list-container">
    <vx-card title="Filtros">
      <div class="flex">
        <div class="w-1/3">
          <vs-select
            label="Metódos de pago"
            class="w-full"
            v-model="paymentMethod"
            placeholder="Seleccione un metodo"
          >
            <vs-select-item
              :key="index"
              :value="item.key"
              :text="item.value"
              v-for="(item, index) in paymentMethods"
            />
          </vs-select>
        </div>
        <div class="vx-col sm:w-1/3 w-full mx-3">
          <vs-select
            label="Socio logístico"
            v-model="deliverymenId"
            class="w-full"
            placeholder="Socio logístico"
            autocomplete
          >
            <vs-select-item :value="(item = null)" text="Todos" />
            <vs-select-item
              :value="item.id"
              v-for="(item, index) in deliverymen"
              :key="index"
              v-show="item.data_status == 'verified'"
              :text="`${item.code ? item.code : 'N/A'} - ${
                item.user.first_name + ' ' + item.user.last_name
              }`"
            />
          </vs-select>
        </div>
      </div>
    </vx-card>
    <!-- History state prompt -->

    <vs-prompt
      :active.sync="showHistoricalPrompt"
      buttons-hidden
      title="Historial de estados"
    >
      <vs-table
        class="mt-3"
        v-if="selectedService && selectedService.stateHistorical"
        :data="selectedService.stateHistorical"
      >
        <template slot="thead">
          <vs-th> Estado </vs-th>
          <vs-th> Fecha </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.state.name }} </vs-td>
            <vs-td>{{ tr.created_at }} </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-prompt>
    <!-- Detail SERVICE -->
    <vs-prompt
      :active.sync="showDetailPrompt"
      title="Detalle del Servicio"
      @cancel="resetDetailPrompt"
      @accept="saveNewDescriptions"
      @close="selectedService = null"
      accept-text="Guardar"
      cancel-text="Descartar"
    >
      <request-input-list
        class="ml-4"
        v-if="selectedService"
        addresses-disabled
        :requests="selectedService.directions"
      ></request-input-list>
    </vs-prompt>
    <!-- Detail SERVICE -->

    <!-- Service image -->
    <vs-prompt
      :active.sync="showServiceImagePrompt"
      title="Fotos del servicio"
      @cancel="resetServiceImagePrompt"
      :buttons-hidden="true"
    >
      <div class="my-3" v-if="selectedService">
        <h6 class="service-name font-medium truncate mb-2">Observación</h6>
        <p v-if="selectedService.observation">
          {{ selectedService.observation }}
        </p>
        <p v-else>N/A</p>

        <h6 class="service-name font-medium truncate mt-5 mb-2">Imágenes</h6>
        <vs-table v-if="selectedService.images" :data="selectedService.images">
          <template slot="thead">
            <vs-th> Aliado </vs-th>
            <vs-th> Fecha </vs-th>
            <vs-th> Imagen </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                {{ tr.deliveryman.user.first_name }}
                {{ tr.deliveryman.user.last_name }}
              </vs-td>
              <vs-td>{{ tr.created_at }} </vs-td>
              <vs-td>
                <span class="flex justify-center items-center w-full">
                  <feather-icon
                    v-if="tr.url"
                    icon="ImageIcon"
                    svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                    @click="viewImage(tr.url)"
                  />
                </span>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vs-prompt>
    <!-- Service image -->

    <edit-service-modal
      @close="serviceToEdit = null"
      v-model="editServiceModal"
      v-if="serviceToEdit"
      :service="serviceToEdit"
    />
    <vs-prompt
      class="socios-table__prompt"
      :active.sync="activePromptChangeState"
      title="Cambiar estado al servicio."
      @accept="sendChangeStateService"
      accept-text="Guardar"
      cancel-text="Descartar"
    >
      <h6 class="service-name font-medium truncate mb-2">
        Estado actual del servicio:
      </h6>
      <p class="service-name font-medium truncate mb-3">
        <vs-chip
          v-if="selectedService"
          color="primary"
          class="product-order-status"
        >
          {{ selectedService.state.name }}
        </vs-chip>
      </p>
      <vs-select
        class="w-full"
        label="Todos los estados disponibles."
        v-model="currentStateId"
      >
        <vs-select-item
          :key="index"
          :text="item.name"
          :value="item.id"
          v-for="(item, index) in statesServices.filter(
            (e) => e.code !== 'CANCELLED'
          )"
        />
      </vs-select>
    </vs-prompt>

    <vs-prompt
      @cancel="val = ''"
      title="Confirme clave especial."
      @accept="confirmPassword(service)"
      accept-text="Cancelar"
      @close="showCancelDialog"
      cancel-text="Retroceder"
      :active.sync="showCancelDialog"
    >
      <vs-input
        class="w-full"
        label-placeholder="Ingresa la clave especial."
        v-model="specialKey"
        type="password"
      ></vs-input>
      <vs-input
        class="w-full"
        label-placeholder="Vuelva a ingresar la clave especial."
        v-model="confirmSpecialKey"
        type="password"
      ></vs-input>
    </vs-prompt>
    <vx-card class="shadow-none bg-transparent">
      <StatusTabs
        v-if="serviceStatuses"
        :status="serviceStatuses.filter((e) => !e.is_done)"
        @on-state-change="stateId = $event"
      ></StatusTabs>
      <vs-input
        class="float-right w-full mb-5"
        label-placeholder="Buscar servicio"
        v-model="search"
        type="text"
      ></vs-input>
      <app-loading :loading="loading"></app-loading>
      <vs-table
        ref="table"
        v-model="selected"
        :max-items="itemsPerPage"
        :total="total"
        :data="currentServices.data"
        v-if="
          currentServices &&
          currentServices.data &&
          currentServices.paginatorInfo &&
          !loading
        "
        sst
        no-data-text="No hay servicios disponibles"
        @change-page="setCurrentPage"
      >
        <template slot="thead">
          <vs-th>Acciones</vs-th>
          <vs-th sort-key="id">Ref</vs-th>
          <vs-th sort-key="">Valor</vs-th>
          <vs-th sort-key="">Forma de pago</vs-th>
          <vs-th sort-key="">Remitente</vs-th>
          <vs-th sort-key="">Tel remitente</vs-th>
          <vs-th sort-key="">Destinatario</vs-th>
          <vs-th sort-key="">Tel destinatario</vs-th>
          <vs-th sort-key="">Partner</vs-th>
          <vs-th sort-key="">Tel Partner</vs-th>
          <vs-th sort-key="">N. Factura</vs-th>
          <vs-th sort-key="">V. del pedido</vs-th>
          <vs-th sort-key="">Estado</vs-th>
          <vs-th sort-key="">Ida y vuelta</vs-th>
          <vs-th sort-key="">Fecha y hora</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              :style="{ backgroundColor: getServiceColor(indextr) }"
            >
              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  icon="InfoIcon"
                  @click="showStateDetail(tr)"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
                <feather-icon
                  icon="ClockIcon"
                  @click="showStateHistorical(tr)"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
                <feather-icon
                  icon="EditIcon"
                  @click="
                    $gates.hasPermission('edit_services') &&
                      showEditServiceForm(tr)
                  "
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
                <feather-icon
                  icon="TrashIcon"
                  @click="
                    $gates.hasPermission('edit_services') && confirmCancel(tr)
                  "
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                />
                <feather-icon
                  icon="ImageIcon"
                  @click="showServiceImageDetail(tr)"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
              </vs-td>
              <vs-td>
                <p class="service-name font-medium truncate">{{ tr.id }}</p>
              </vs-td>
              <!-- Valor hardcode-->
              <vs-td>
                <p class="service-name font-medium truncate">
                  {{ tr.value | currencyFormat }}
                </p>
              </vs-td>
              <!-- Valor hardcode-->
              <vs-td>
                <p class="service-name font-medium truncate">
                  {{ tr.payment_method == 'CASH' ? 'Efectivo' : 'Credito' }}
                </p>
              </vs-td>

              <vs-td>
                <p class="service-name font-medium truncate">
                  {{ tr.sender && tr.sender.name ? tr.sender.name : 'N/A' }}
                </p>
              </vs-td>

              <vs-td>
                <p class="service-name font-medium truncate">
                  {{ tr.sender && tr.sender.phone ? tr.sender.phone : 'N/A' }}
                </p>
              </vs-td>

              <vs-td>
                <p class="service-name font-medium truncate">
                  {{
                    tr.destinatary && tr.destinatary.name
                      ? tr.destinatary.name
                      : 'N/A'
                  }}
                </p>
              </vs-td>

              <vs-td>
                <p class="service-name font-medium truncate">
                  {{
                    tr.destinatary && tr.destinatary.phone
                      ? tr.destinatary.phone
                      : 'N/A'
                  }}
                </p>
              </vs-td>

              <vs-td>
                <p class="service-name font-medium truncate">
                  {{
                    tr.deliveryman
                      ? tr.deliveryman.code +
                        ' - ' +
                        tr.deliveryman.user.first_name +
                        ' ' +
                        tr.deliveryman.user.last_name
                      : 'N/A'
                  }}
                </p>
              </vs-td>

              <vs-td>
                <p class="service-name font-medium truncate">
                  {{ tr.deliveryman ? tr.deliveryman.user.phone : 'N/A' }}
                </p>
              </vs-td>

              <vs-td>
                <p class="service-name font-medium truncate">
                  {{ tr.invoice ? tr.invoice : 'N/A' }}
                </p>
              </vs-td>

              <vs-td>
                <p
                  v-if="tr.order_value"
                  class="service-name font-medium truncate"
                >
                  {{ tr.order_value | currencyFormat }}
                </p>
                <p
                  v-if="!tr.order_value"
                  class="service-name font-medium truncate"
                >
                  N/A
                </p>
              </vs-td>

              <vs-td>
                <p class="service-name font-medium truncate">
                  <vs-chip
                    color="primary"
                    class="product-order-status"
                    @click.native="promptChangeState(tr)"
                  >
                    {{ tr.state.name }}
                  </vs-chip>
                </p>
              </vs-td>

              <vs-td>
                <p class="service-name font-medium truncate">
                  <!-- {{ tr.round_trip ? 'Si' : 'No' }} -->

                  <feather-icon
                    v-if="tr.round_trip"
                    icon="CheckIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    class="ml-2"
                  />
                  <feather-icon
                    v-else
                    icon="XCircleIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    class="ml-2"
                  />
                </p>
              </vs-td>

              <vs-td>
                <p class="service-name font-medium truncate">
                  {{ tr.created_at }}
                </p>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />
    </vx-card>
  </div>
</template>

<script>
import { CANCEL_SERVICE } from '@/graphql/mutations';
import { UPDATE_STATE_SERVICE, UPDATE_SERVICE } from '@/graphql/mutations';
import { CURRENT_SERVICES } from '@/graphql/queries';
import { ALL_STATES_SERVICES } from '@/graphql/queries';
import EditServiceModal from '@/components/panel-de-control/modals/EditServiceModal';
import StatusTabs from '@/components/solicitud/StatusTabs';
import RequestInputList from '@/components/solicitud/RequestInputList.vue';
import VxCard from '../components/vx-card/VxCard.vue';
import AppLoading from '@/components/AppLoading';
import { DELIVERYMEN } from '@/graphql/queries';

export default {
  components: {
    EditServiceModal,
    StatusTabs,
    RequestInputList,
    VxCard,
    AppLoading,
  },
  data() {
    return {
      search: '',
      selected: [],
      selectedDirection: '',
      showEdit: false,
      stateId: '',
      activePromptChangeState: false,
      statesServices: [],
      services: [],
      maxPageNumbers: 5,
      itemsPerPage: 20,
      selectedIndex: null,
      currentPage: 1,
      isMounted: false,
      servicesHistory: [],
      showCancelDialog: false,
      specialKey: '',
      confirmSpecialKey: '',
      service: [],
      showHistoricalPrompt: false,
      editServiceModal: false,
      serviceToEdit: null,
      showDetailPrompt: false,
      showServiceImagePrompt: false,
      selectedService: null,
      serviceDelete: {},
      detailService: [],
      currentStateId: '',
      paymentMethod: null,
      loading: false,
      servicesColors: [],
      deliverymenId: null,
      paymentMethods: [
        {
          key: null,
          value: 'Todos',
        },
        {
          key: 'CASH',
          value: 'Efectivo',
        },
        {
          key: 'CREDIT',
          value: 'Credito',
        },
      ],
    };
  },

  apollo: {
    currentServices: {
      query: CURRENT_SERVICES,
      variables() {
        return {
          page: this.currentPage,
          state_id: this.stateId,
          first: this.itemsPerPage,
          search: this.search,
          payment_method: this.paymentMethod,
          deliveryman_id: this.deliverymenId,
        };
      },
      fetchPolicy: 'cache-and-network',
      // subscribeToMore: {
      //   document: SERVICE_UPDATED_SUBSCRIPTION,
      //   // Variables passed to the subscription. Since we're using a function,
      //   // they are reactive
      //   variables() {
      //     return {};
      //   },
      // },
    },
    serviceStatuses: {
      query: ALL_STATES_SERVICES,
      result({ data }) {
        if (data) {
          this.statesServices = data.serviceStatuses;
        }
      },
    },
    deliverymen: { query: DELIVERYMEN },
  },
  computed: {
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.services.length;
    },
    totalPages() {
      if (this.currentServices && this.currentServices.paginatorInfo) {
        return Math.ceil(
          this.currentServices.paginatorInfo.total /
            this.currentServices.paginatorInfo.perPage
        );
      }
      return 0;
    },
    total() {
      return this.currentServices.paginatorInfo
        ? this.currentServices.paginatorInfo.total
        : 0;
    },
  },
  methods: {
    async saveNewDescriptions() {
      console.log(this.selectedService);
      this.selectedService.directions.forEach((item) => {
        delete item.__typename;
        delete item.coordinates.__typename;
      });
      console.log(this.selectedService);
      await this.$apollo
        .mutate({
          mutation: UPDATE_SERVICE,
          variables: {
            input: {
              id: this.selectedService.id,
              hashid: this.selectedService.hashid,
              directions: this.selectedService.directions,
            },
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Servicio actualizado correctamente!',
            text: 'El servicio en curso ha sido correctamente actualizado.',
          });
        });
    },
    resetDetailPrompt() {
      this.showDetailPrompt = false;
      this.selectedService = null;
    },
    resetServiceImagePrompt() {
      this.showServiceImagePrompt = false;
      this.selectedService = null;
    },
    getDirectionService(indextr, service) {
      this.selectedIndex = indextr;
      this.selectedDirection = service.description;
    },

    setCurrentPage(page) {
      this.currentPage = page;
    },
    sendChangeStateService() {
      // TODO - Mutacion para cambio de estado del backend
      const service = this.currentServices.data.find(
        (e) => e.id == this.selectedService.id
      );
      if (service) {
        service.state = this.serviceStatuses.find(
          (e) => e.id == this.currentStateId
        );
        this.changeServiceState(service.id, service.state.id);
      }
    },
    promptChangeState(service) {
      this.serviceDelete = { ...service };
      this.activePromptChangeState = true;
      this.selectedService = service;
      this.currentStateId = service.state.id;
    },

    showEditServiceForm(service) {
      this.editServiceModal = true;
      this.serviceToEdit = service;
    },

    showStateHistorical(service) {
      this.showHistoricalPrompt = true;
      this.selectedService = service;
    },
    showStateDetail(service) {
      this.selectedService = JSON.parse(JSON.stringify(service));
      this.showDetailPrompt = true;
      this.currentStateId = service.state.id;
    },
    showServiceImageDetail(service) {
      this.selectedService = JSON.parse(JSON.stringify(service));
      this.showServiceImagePrompt = true;
    },
    confirmCancel(item) {
      this.showCancelDialog = true;
      this.service = item;
    },
    async confirmPassword(service) {
      if (this.specialKey === this.confirmSpecialKey && this.specialKey) {
        this.serviceDelete = { ...service };
        await this.cancelService(service.id, this.specialKey);
        this.specialKey = '';
        this.confirmSpecialKey = '';
      } else {
        this.$vs.notify({
          color: 'danger',
          title: 'Servicio en curso no ha sido eliminado.',
          text: 'La clave especial no coincide.',
        });
      }
    },
    async cancelService(id, specialKey) {
      await this.$apollo
        .mutate({
          mutation: CANCEL_SERVICE,
          variables: {
            id,
            specialKey,
          },
          update: (cache, { data: { cancelService } }) => {
            console.log(this.paymentMethod);
            try {
              const data = cache.readQuery({
                query: CURRENT_SERVICES,
                variables: {
                  first: this.itemsPerPage,
                  page: this.currentPage,
                  payment_method: this.paymentMethod,
                  search: this.search,
                  state_id: this.stateId,
                },
              });
              console.log(data);
              this.currentServices = data;
              const removeIndex = data.currentServices.data
                .map((item) => item.id)
                .indexOf(cancelService.id);
              console.log(removeIndex);
              ~removeIndex && data.currentServices.data.splice(removeIndex, 1);
              console.log(data);
              cache.writeQuery({
                query: CURRENT_SERVICES,
                data,
                variables: {
                  first: this.itemsPerPage,
                  page: this.currentPage,
                  payment_method: this.paymentMethod,
                  search: this.search,
                  state_id: this.stateId,
                },
              });
            } catch (error) {
              console.log();
            }
            // try {
            //   const data = cache.readQuery({
            //     query: SERVICES_HISTORY,
            //     variables: {
            //       page: 1,
            //       state_id: cancelService.state.id,
            //       first: 5,
            //     },
            //   });
            //   data.servicesHistory.data.unshift(cancelService);
            //   cache.writeQuery({
            //     data,
            //     query: SERVICES_HISTORY,
            //     variables: {
            //       page: 1,
            //       state_id: cancelService.state.id,
            //       first: 5,
            //     },
            //   });
            // } catch {
            //   console.log();
            // }
            // try {
            //   const data = cache.readQuery({
            //     query: SERVICES_HISTORY,
            //     variables: {
            //       page: 1,
            //       state_id: '',
            //       first: 5,
            //     },
            //   });
            //   data.servicesHistory.data.unshift(cancelService);
            //   cache.writeQuery({
            //     data,
            //     query: SERVICES_HISTORY,
            //     variables: {
            //       page: 1,
            //       state_id: '',
            //       first: 5,
            //     },
            //   });
            // } catch {
            //   console.log();
            // }
            // try {
            //   const cacheData = cache.readQuery({
            //     query: CURRENT_SERVICES,
            //     variables: {
            //       page: this.currentPage,
            //       state_id: this.serviceDelete.state.id,
            //       first: this.itemsPerPage,
            //     },
            //   });
            //   const removeIndex = cacheData.currentServices.data
            //     .map((item) => item.id)
            //     .indexOf(cancelService.id);

            //   ~removeIndex &&
            //     cacheData.currentServices.data.splice(removeIndex, 1);

            //   cache.writeQuery({
            //     data: cacheData,
            //     query: CURRENT_SERVICES,
            //     variables: {
            //       page: this.currentPage,
            //       state_id: this.serviceDelete.state.id,
            //       first: this.itemsPerPage,
            //     },
            //   });
            // } catch {
            //   console.log();
            // }
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Servicio en curso eliminado.',
            text: 'El servicio en curso ha sido eliminado satisfactoriamente.',
          });
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Servicio en curso no ha sido eliminado.',
            text: 'La clave especial es incorrecta.',
          });
        });
    },
    async changeServiceState(id, state_id) {
      await this.$apollo
        .mutate({
          mutation: UPDATE_STATE_SERVICE,
          variables: {
            id,
            state_id,
          },
          update: (cache, { data: { updateStatusService } }) => {
            try {
              const data = cache.readQuery({
                query: CURRENT_SERVICES,
                variables: {
                  page: this.currentPage,
                  state_id: updateStatusService.state.id,
                  first: this.itemsPerPage,
                },
              });
              data.currentServices.data.unshift(updateStatusService);
              cache.writeQuery({
                query: CURRENT_SERVICES,
                data,
                variables: {
                  page: 1,
                  state_id: updateStatusService.state.id,
                  first: 5,
                },
              });
            } catch {
              console.log();
            }
            try {
              const data = cache.readQuery({
                query: CURRENT_SERVICES,
                variables: {
                  page: this.currentPage,
                  state_id: this.serviceDelete.state.id,
                  first: this.itemsPerPage,
                },
              });
              this.currentServices = data;
              const removeIndex = data.currentServices.data
                .map((item) => item.id)
                .indexOf(updateStatusService.id);
              ~removeIndex && data.currentServices.data.splice(removeIndex, 1);
              cache.writeQuery({
                query: CURRENT_SERVICES,
                data,
                variables: {
                  page: this.currentPage,
                  state_id: this.serviceDelete.state.id,
                  first: this.itemsPerPage,
                },
              });
            } catch {
              console.log();
            }
          },
        })
        .then((data) => {
          this.selectedService.stateHistorical =
            data.data.updateStatusService.stateHistorical;
          this.$vs.notify({
            title: 'Se ha cambiado el estado del servicio',
            text: `El estado nuevo del servicio es ${data.data.updateStatusService.state.name}`,
            color: 'success',
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: 'No se ha cambiado el estado del servicio',
            text: `Error al cambiar el estado del servicio.`,
            color: 'danger',
          });
        });
    },

    getServiceColor(index) {
      if (this.servicesColors.length > index) {
        return this.servicesColors[index];
      }
    },

    updateServicesColorsArray() {
      if (
        typeof this.currentServices !== 'undefined' &&
        this.currentServices.hasOwnProperty('data')
      ) {
        this.currentServices.data.forEach((service, index) => {
          if (this.serviceStateIsDue(index)) {
            this.servicesColors[index] = this.getAlarmColor(index);
            this.$forceUpdate();
          } else {
            this.servicesColors[index] = '#FFFFFF';
          }
        });
      }
    },

    serviceStateIsDue(index) {
      if (!this.serviceStateIsValid(index)) return false;

      let elapsedInMinutesLastChange = this.elapsedLastStateInMinutes(
        new Date(this.getLastStateChange(index).created_at)
      );

      let alarmMaxTime = this.getAlarmMaxTime(index);

      if (elapsedInMinutesLastChange > alarmMaxTime) return true;
      return false;
    },

    serviceStateIsValid(index) {
      return (
        this.stateHasAlarm(index) &&
        this.serviceHasStatesHistorical(index) &&
        this.currentStateMatchLastStatesHistoricalRecord(index)
      );
    },

    getServiceObject(index) {
      return this.currentServicesAreLoaded()
        ? this.currentServices.data[index]
        : {};
    },

    currentServicesAreLoaded() {
      return this.currentServices.data;
    },

    stateHasAlarm(index) {
      let service = this.getServiceObject(index);
      return service.state.alarms.length != 0;
    },

    serviceHasStatesHistorical(index) {
      let service = this.getServiceObject(index);
      return service.stateHistorical.length != 0;
    },

    getLastStateChange(index) {
      let service = this.getServiceObject(index);

      if (this.serviceHasStatesHistorical(index)) {
        let historicalCount = service.stateHistorical.length;
        return service.stateHistorical[historicalCount - 1];
      }

      return null;
    },

    currentStateMatchLastStatesHistoricalRecord(index) {
      let service = this.getServiceObject(index);

      return service.state.id == this.getLastStateChange(index).state.id;
    },

    getAlarmColor(index) {
      return this.getLastStateChange(index).state.alarms[0].color;
    },

    getAlarmMaxTime(index) {
      return this.getLastStateChange(index).state.alarms[0].max_time;
    },

    elapsedLastStateInMinutes(time) {
      let now = Date.now();
      let diff = Math.abs(now - time) / 1000;
      let days = diff / 86400;
      let hours = days * 24;
      return hours * 60;
    },

    viewImage(value) {
      window.open(value);
    },
  },

  watch: {
    paymentMethod() {
      this.currentPage = 1;
    },
    deliverymenId() {
      this.currentPage = 1;
    },
    stateId: function () {
      this.currentPage = 1;
    },
    '$apollo.loading'(value) {
      this.loading = value;
    },
    currentServices: function () {
      this.updateServicesColorsArray();
    },
  },
  mounted() {
    // this.$apollo.queries.currentServices.subscribeToMore({
    //   document: NEW_SERVICE,
    //   updateQuery: (previousResult, { subscriptionData }) => {
    //     console.log(
    //       '🚀 ~ file: PanelControl.vue ~ line 831 ~ mounted ~ subscriptionData',
    //       subscriptionData
    //     );
    //     console.log(
    //       '🚀 ~ file: PanelControl.vue ~ line 831 ~ mounted ~ previousResult',
    //       previousResult
    //     );

    //     if (
    //       previousResult.currentServices.data.find(
    //         (s) => s.id == subscriptionData.data.newService.id
    //       )
    //     ) {
    //       return previousResult;
    //     }
    //     previousResult.currentServices.data.unshift(
    //       subscriptionData.data.newService
    //     );
    //     console.log(
    //       '🚀 ~ file: PanelControl.vue ~ line 848 ~ mounted ~ previousResult',
    //       previousResult
    //     );

    //     return previousResult;
    //   },
    // });

    this.$socket.client.on('serviceUpdated', async () => {
      await this.$apollo.queries.currentServices.refetch();
      this.$forceUpdate();
    });

    this.$socket.client.on('newService', async () => {
      await this.$apollo.queries.currentServices.refetch();
      this.$forceUpdate();
    });
    this.isMounted = true;

    setInterval(() => {
      this.updateServicesColorsArray();
    }, 2000);
  },
};
</script>

<style lang="scss">
.panel-control {
  &__details-prompt {
    height: 300px;
    overflow: auto;
  }
}

.input-edit {
  display: flex;
  height: 39.59px;
  border-radius: 5px;
  width: 230px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.input-edit input {
  border-radius: 10px;
  border: none;
  color: #2d4e60;
  padding-left: 8px;
}

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .service-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table-text {
      text-transform: none !important;
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
