var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal transition-all duration-100"},[_c('vs-prompt',{staticClass:"modal transition-all duration-100",attrs:{"active":_vm.editServiceModal,"buttons-hidden":"","title":"Edición del Servicio"},on:{"update:active":function($event){_vm.editServiceModal=$event}}},[(!_vm.loading)?_c('ValidationObserver',{ref:"observerClave",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"modal-content"},[_c('ValidationProvider',{attrs:{"name":"forma de pago","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full mb-3",attrs:{"label":"Forma de pago"},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}},[_c('vs-select-item',{attrs:{"value":"CASH","text":"Efectivo"}}),_c('vs-select-item',{attrs:{"value":"CREDIT","text":"Crédito"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Socio logístico","vid":"Socio logístico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full mb-4",attrs:{"label":"Socio logístico","placeholder":"Socio logístico","autocomplete":""},model:{value:(_vm.deliverymanId),callback:function ($$v) {_vm.deliverymanId=$$v},expression:"deliverymanId"}},_vm._l((_vm.deliverymen),function(item,index){return (item.data_status == 'verified')?_c('div',{key:index,staticClass:"items-select",attrs:{"text":item.user.first_name,"value":item.id}},[_c('vs-select-item',{attrs:{"value":item.id,"text":((item.code ? item.code : 'N/A') + " - " + (item.user.first_name + ' ' + item.user.last_name))}})],1):_vm._e()}),0),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"font-bold"},[_vm._v("Sobreescribir aliado")]),_c('vs-switch',{attrs:{"data-test":"overwriteDeliverymanSwitch"},model:{value:(_vm.overwriteDeliveryman),callback:function ($$v) {_vm.overwriteDeliveryman=$$v},expression:"overwriteDeliveryman"}})],1),_c('div',{staticClass:"flex items-center justify-between mt-6"},[_c('label',{staticClass:"font-bold"},[_vm._v("Ida y vuelta")]),_c('vs-switch',{attrs:{"data-test":"roundAndTripSwitch"},model:{value:(_vm.roundAndTrip),callback:function ($$v) {_vm.roundAndTrip=$$v},expression:"roundAndTrip"}})],1),(_vm.roundAndTrip)?_c('vs-input',{staticClass:"w-full mt-3",attrs:{"id":"roundAndTripPercent","label":"Porcentaje IYV"},model:{value:(_vm.roundAndTripPercent),callback:function ($$v) {_vm.roundAndTripPercent=$$v},expression:"roundAndTripPercent"}}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Valor","vid":"Valor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-3",attrs:{"id":"value","label":"Valor"},model:{value:(_vm.newValue),callback:function ($$v) {_vm.newValue=$$v},expression:"newValue"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"flex mt-3"},[_c('vs-button',{attrs:{"icon-pack":"feather","icon":"icon-send","disabled":invalid},on:{"click":_vm.submit}},[_vm._v("Actualizar")]),_c('vs-button',{staticClass:"text-san-juan",attrs:{"icon-pack":"feather","type":"submit"},on:{"click":function($event){_vm.editServiceModal = false}}},[_vm._v("Descartar")])],1)]}}],null,false,7707754)}):_c('div',{staticClass:"pt-20 pb-24 transition-all duration-100"},[_c('p',{staticClass:"text-center text-sm text-slate-gray"},[_vm._v("Cargando...")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }