<template>
  <div class="modal transition-all duration-100">
    <vs-prompt
      class="modal transition-all duration-100"
      :active.sync="editServiceModal"
      buttons-hidden
      title="Edición del Servicio"
    >
      <ValidationObserver
        v-if="!loading"
        ref="observerClave"
        v-slot="{ invalid }"
      >
        <div class="modal-content">
          <ValidationProvider
            name="forma de pago"
            rules="required"
            v-slot="{ errors }"
          >
            <vs-select
              class="w-full mb-3"
              label="Forma de pago"
              v-model="paymentMethod"
            >
              <vs-select-item value="CASH" text="Efectivo"></vs-select-item>
              <vs-select-item value="CREDIT" text="Crédito"></vs-select-item>
            </vs-select>
          </ValidationProvider>
          <ValidationProvider
            name="Socio logístico"
            vid="Socio logístico"
            v-slot="{ errors }"
            rules="required"
          >
            <vs-select
              label="Socio logístico"
              v-model="deliverymanId"
              class="w-full mb-4"
              placeholder="Socio logístico"
              autocomplete
            >
              <div
                class="items-select"
                :text="item.user.first_name"
                :value="item.id"
                v-for="(item, index) in deliverymen"
                :key="index"
                v-if="item.data_status == 'verified'"
              >
                <vs-select-item
                  :value="item.id"
                  :text="`${item.code ? item.code : 'N/A'} - ${
                    item.user.first_name + ' ' + item.user.last_name
                  }`"
                />
              </div>
            </vs-select>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="flex items-center justify-between">
            <label class="font-bold">Sobreescribir aliado</label>
            <vs-switch
              data-test="overwriteDeliverymanSwitch"
              v-model="overwriteDeliveryman"
            />
          </div>

          <div class="flex items-center justify-between mt-6">
            <label class="font-bold">Ida y vuelta</label>
            <vs-switch data-test="roundAndTripSwitch" v-model="roundAndTrip" />
          </div>

          <vs-input
            id="roundAndTripPercent"
            v-if="roundAndTrip"
            v-model="roundAndTripPercent"
            class="w-full mt-3"
            label="Porcentaje IYV"
          />
          <ValidationProvider
            name="Valor"
            vid="Valor"
            v-slot="{ errors }"
            rules="required"
          >
            <vs-input
              id="value"
              v-model="newValue"
              class="w-full mt-3"
              label="Valor"
            />
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="flex mt-3">
          <vs-button
            icon-pack="feather"
            icon="icon-send"
            @click="submit"
            :disabled="invalid"
            >Actualizar</vs-button
          >
          <vs-button
            class="text-san-juan"
            icon-pack="feather"
            type="submit"
            @click="editServiceModal = false"
            >Descartar</vs-button
          >
        </div>
      </ValidationObserver>
      <div v-else class="pt-20 pb-24 transition-all duration-100">
        <p class="text-center text-sm text-slate-gray">Cargando...</p>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { UPDATE_SERVICE } from '@/graphql/mutations';
import { DELIVERYMEN } from '@/graphql/queries';
export default {
  name: 'EditServiceModal',
  props: ['value', 'service'],

  data() {
    return {
      deliverymanSelected: [],
      deliverymanId: null,
      newValue: 0,
      paymentMethod: null,
      roundAndTrip: false,
      overwriteDeliveryman: false,
      roundAndTripPercent: 20,
      loading: false,
    };
  },
  computed: {
    editServiceModal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        if (!value) {
          this.$emit('close', value);
        }
      },
    },
  },
  watch: {
    roundAndTrip() {
      this.roundAndTripChange();
      if (this.service.round_trip && this.roundAndTrip) {
        this.newValue = Math.round(
          this.service.value / 1 + this.roundAndTripPercent / 100
        );
      }
      console.log(this.roundAndTrip);
      console.log(this.service.value);
      if (this.service.round_trip && !this.roundAndTrip) {
        this.newValue = Math.round(
          this.service.value / (1 + this.roundAndTripPercent / 100)
        );
        // console.log(this.newValue);
      }
    },
    roundAndTripPercent(value) {
      console.log(value);
      this.newValue = this.service.value + this.service.value * (value / 100);
    },
  },
  mounted() {
    this.newValue = this.service.value;
    if (this.service.round_trip) {
      this.newValue = Math.round(
        this.service.value / 1 + this.roundAndTripPercent / 100
      );
    }
    if (this.service.deliveryman && this.service.deliveryman.id) {
      this.deliverymanId = this.service.deliveryman.id;
    }
    this.paymentMethod = this.service.payment_method;
    this.roundAndTrip = this.service.round_trip;
  },
  apollo: {
    deliverymen: {
      query: DELIVERYMEN,
    },
  },
  methods: {
    roundAndTripChange() {
      if (this.roundAndTrip) {
        this.newValue =
          this.newValue + this.service.value * (this.roundAndTripPercent / 100);
      } else {
        this.newValue =
          this.newValue - this.service.value * (this.roundAndTripPercent / 100);
      }
    },
    async submit() {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: UPDATE_SERVICE,
          variables: {
            input: {
              id: this.service.id,
              value: this.newValue,
              deliveryman_id: this.deliverymanId,
              payment_method: this.paymentMethod,
              round_trip: this.roundAndTrip,
              overwrite_deliveryman: this.overwriteDeliveryman,
            },
          },
        })
        .then(() => {
          this.editServiceModal = false;
          this.$vs.notify({
            color: 'success',
            title: 'Valor del servicio actualizado',
            text: 'El valor del servicio se ha actualizado correctamente.',
          });
          this.loading = false;
        })
        .catch((error) => {
          this.$vs.notify({
            color: 'danger',
            title: 'Error al actualizar el valor de un servicio',
            text: `${error.graphQLErrors.map((r) => r.message)}`,
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
